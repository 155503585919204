import React, { useEffect } from "react";
import { Link } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";

import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Services from "../components/services/services";

import "../styles/pages/_overal-styling.scss";
import "../styles/pages/_animation.scss";

import introImg from "../images/pages/TSD_How_we_work_hero.png";
import onboardingIcon from "../images/pages/TSD_How_We_Work_Icons_Onboarding.svg";
import discoveryIcon from "../images/pages/TSD_How_We_Work_Icons_Discovery.svg";
import strategyIcon from "../images/pages/TSD_How_We_Work_Icons-03.svg";
import starIcon from "../images/pages/TSD_How_We_Work_Icons_Project_Management.svg";
import bigMouth from "../images/pages/TSD_How_we_work_Resources.png";
import megaphone from "../images/pages/TSD_How_we_work_Megaphone.png";
import bubble from "../images/pages/TSD_Bubble-02.png";
import handIcon from "../images/pages/TSD_How_We_Work_Icons-Resources.svg";

const HowWeWork = () => {
  useEffect(() => {
    const readmoreBtn = document.querySelector("#readmore-btn");
    const readmoreText = document.querySelector("#readmore-text");
    readmoreBtn.addEventListener("click", () =>
      handleDisplay(readmoreText, readmoreBtn)
    );
    const focusWord = document.getElementById("focus-word");

    return () => clearInterval(timer);
  }, []);

  const handleDisplay = (readmoreText, readmoreBtn) => {
    readmoreText.classList.toggle("h100");
    if (readmoreText.classList.contains("h100")) {
      readmoreBtn.innerText = "Read Less";
    } else {
      readmoreBtn.innerText = "Read More";
    }
  };

  var timer;
  function startTimer() {
    const focusWords = [
      "professional services",
      "medical services",
      "consulting",
      "fintech",
      "manufacturing",
      "finance",
      "accounting",
      "wellness",
      "creative",
      "ecommerce",
    ];

    let i = 0;
    timer = setInterval(function () {
      if (i == focusWords.length) {
        i = 0;
      }
      document.getElementById("focus-word").innerText = focusWords[i];
      i++;
    }, 2500);
  }

  startTimer();

  return (
    <Layout type="howwork">
      <SEO
        title="How We Work"
        description="Meet The Simple Department and see how we work. Learn how we can take the stress out of marketing your company."
      />

      <section className="how-work-intro">
        <div className="container wrapper">
          <div className="intro-text">
            <h1>Business is complicated. Marketing should be Simple.</h1>
            <p className="small-padding">
              You started a business because you were passionate about what your
              business does – not to waste your time and energy figuring out how
              to market that business.
            </p>
            <p>
              That’s where <strong>The Simple Department</strong> Comes in.
            </p>
          </div>
        </div>
        <img src={introImg} alt="" />
      </section>
      <section className="how-work-main">
        <div className="stress-section">
          <div className="container">
            <h2>
              Learn how The Simple Department can take the stress out of
              marketing your company, so you can focus on<br></br>
              <span id="focus-word" className="red-underline">
                ecommerce
              </span>
            </h2>
            <div className="btn-wrapper" style={{ display: "none" }}>
              <Link to="#" className="btn sqr-btn-blue">
                SHOW ME!
              </Link>
              <a
                className="btn sqr-btn-blue-outline right-btn"
                href="https://www.stress.org/"
                target="_blank"
              >
                No. I like stress.
              </a>
            </div>
          </div>
        </div>
        <div className="slanted-section">
          <div className="container">
            <p style={{ paddingBottom: "30px" }}>
              When we say “simple”, we mean the elegant simplicity of something
              that just works. Our system ditches the complexity that usually
              comes with working with a marketing agency to deliver exactly what
              you need (and nothing you don’t).
            </p>
            <div className="card-wrapper">
              <div className="card">
                <img src={onboardingIcon} />
                <h3>Onboarding</h3>
                <p>
                  As soon as you choose The Simple Department, the work begins.
                  We have an efficient digital onboarding process that collects
                  all your information, explains how the process will unfold and
                  key steps, and then ensures the right people on our team have
                  the information they need to kick things off – starting with
                  your discovery call.
                </p>
              </div>
              <div className="card">
                <img src={discoveryIcon} />
                <h3>Discovery</h3>
                <p>
                  Here is where we get to know you – your hopes and dreams, your
                  passions, your fears…. Okay, mostly just what your business is
                  all about, your goals, and what you’re hoping to achieve with
                  marketing. Then we assign the right Simple Department team
                  members to your account.
                </p>
              </div>
              <div className="card">
                <img src={strategyIcon} />
                <h3>Strategy & Project Brief</h3>
                <p>
                  This is where we really get to work. We outline the best
                  strategy to meet the goals we talked about in the last step,
                  including how our team will execute that strategy, and provide
                  you with a project brief so you know exactly what’s happening.
                </p>
              </div>
            </div>
            <div className="btn-wrapper" style={{ display: "none" }}>
              <Link
                to="#"
                className="btn sqr-btn-white"
                style={{ marginTop: "40px" }}
              >
                SEE SOME EXAMPLES!
              </Link>
            </div>
          </div>
        </div>
        <div className="color-cards">
          <div className="container">
            <div className="card-wrapper">
              <div className="card img-holder">
                <h3>Project Management</h3>
                <p>
                  Part of simplicity is transparency. That’s why you’ll have
                  24/7 access to our project management software – ClickUp.
                  There you can track our progress in real time, keep an eye on
                  how much time we’re using, and add any comments or questions
                  you have.
                </p>
                <img src={starIcon} />
              </div>
              <div className="card">
                <h3>Communication</h3>
                <p>
                  What keeps things simple is clear, concise communication
                  between consenting adults. We do everything in our power to
                  communicate clearly and openly, so if you have questions or
                  concerns reach out – a human will answer you! You can check
                  ClickUp 24/7 but we don’t ask our team to work evenings and
                  weekends so, unless you have an emergency, our team will
                  respond promptly during business hours.
                </p>
              </div>
              <div className="card">
                <h3>Pricing and Payments</h3>
                <p>
                  Simple pricing means a model that is fair for everyone and
                  gives us the time we need to create effective deliverables
                  that get measurable results for your company. It also means we
                  require up-front payments, as this allows us to minimize risk
                  and not waste time chasing clients with invoices.
                </p>
                <p id="readmore-text" className="readmore-text">
                  We do offer structured payments, but it does require doing a
                  little bit of due diligence on you and your business. We are
                  also speaking with PayPal about implementing a BNPL solution
                  that would really open up flexibility for our larger budget
                  items.{" "}
                </p>
                <a id="readmore-btn">Read More</a>
              </div>
            </div>
          </div>
        </div>
        <div className="live-chat">
          <div className="container">
            <div className="side-megaphone">
              <h2>Want to learn more about how we keep things simple?</h2>
              <p>
                Get in touch with a Project Manager via our live chat today!
              </p>
              <div className="btn-wrapper">
                <a className="btn sqr-btn-blue-outline">SAY HELLO!</a>
              </div>
            </div>
          </div>
          <div className="animation-wrapper">
            <ScrollAnimation animateIn="wobble" className="megaphone-animate">
              <img className="megaphone" src={megaphone} />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeIn"
              delay="500"
              className="bubble-animate"
            >
              <img className="bubble" src={bubble} />
            </ScrollAnimation>
            <ScrollAnimation animateIn="wave" delay="500" className="hand-wave">
              <img className="hand-icon" src={handIcon} />
            </ScrollAnimation>
          </div>
        </div>
        <div className="services-section">
          <div className="container inner-wrapper">
            <div className="left">
              <h3>What can we do for you?</h3>
              <p>
                Sometimes you will need a lot of support and a bunch of
                different services – and sometimes you will just need help
                organizing your ideas, coming up with a plan and making it
                easier for you to go and execute more confidently.
              </p>
            </div>
            <div className="right">
              <Services />
            </div>
          </div>
        </div>
        <div className="resources-section" style={{ display: "none" }}>
          <div className="container wrapper">
            <div className="intro-text">
              <h1>We have some amazing free resources!</h1>
              <div className="btn-wrapper">
                <Link
                  to="#"
                  className="btn sqr-btn-white"
                  style={{ marginTop: "40px" }}
                >
                  CHECK THEM OUT
                </Link>
              </div>
            </div>
          </div>
          <img src={bigMouth} alt="" />
        </div>
      </section>
    </Layout>
  );
};

export default HowWeWork;
